import gif from "./other.gif";
import React, { useState, useEffect } from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import * as anchor from "@project-serum/anchor";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import styled from "styled-components";
import { useAnchorWallet } from "@solana/wallet-adapter-react";

import {
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
} from "./services/candy-machine";
import { useAlert } from 'react-alert'
import { validList } from "./Whitelist";

export default function Mint(props) {

    
  const [error, setError] = React.useState(false)
  const [showMint, setShowMint] = React.useState(false)
  const [value, setValue] = React.useState()


  const onSubmit = (e) => {
    e.preventDefault()
    const condition = Boolean(validList.find(data => data.id === value))
    if (condition) {
      setShowMint(true)
      setError(false)
    } else {
      setShowMint(false)
      setError(true)
    }
    console.log(condition)
  }

  const inputForm = (e) => {
    const wallet = e.target.value
    setValue(wallet)
    console.log(wallet)
  }

  const alert = useAlert()
  const [balance, setBalance] = useState();
  const [isActive, setIsActive] = useState(true); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);
  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState();
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const onMint = async () => {

    try {
      setIsMinting(true);
      if(isMinting){
        alert.show("Minting has started!");
      }
      
      console.log(candyMachine.program);
      if (wallet && candyMachine.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
      
          alert.show('Congratulations! Mint succeeded!')
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
     
          alert.show('Mint failed! Please try again!')
        }
      }
    } catch (error) {
      // TODO: blech:
      // alert.show('Minting failed! Try to Connect Wallet Again')
      
      let message = error.msg || "Minting failed! Please connect wallet again!";
      alert.show(message);
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
          alert.show('SOLD OUT!')
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
          alert.show('Insufficient funds to mint. Please fund your wallet.')
          console.log(message)
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
          alert.show("Minting period hasn't started yet.")
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }

  };

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet,
        props.candyMachineId,
        props.connection
      );
      console.log("IA", itemsAvailable);
      console.log("IR", itemsRemaining);
      console.log("IReddemed", itemsRedeemed);
      console.log("Go LIVE Date", goLiveDate);
      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);
      setCandyMachine(candyMachine);
      console.log(isActive);

      setIsSoldOut(itemsRemaining === 0);
    })();
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);


  return (
    <>
      <div className="topcorner">
        <WalletMultiButton />
      </div>

      <div className="center">
      
            <Section>


<div >
          <img className="img" src={gif} alt="" />
        </div>
<div >
          <button
            className="btn"
            disabled={isSoldOut || isMinting || !isActive}
            onClick={onMint}
          >
            {isSoldOut
              ? "SOLD OUT"
              : isActive
              ? isMinting
                ? "Minting"
                : "MINT NOW"
              : "Count Down"}
          </button>
        </div>
</Section>

        <div></div>
      </div>
    </>
  );
}


const Section = styled.section`

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  text-align:center;
`;

const Intro = styled.div`
  display: flex;
  // flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  @media (max-width: 700px) {
    margin-top: 80px;
  }
  @media (max-width: 300px) {
    margin-left: 26px;
    margin-right: 26px;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 1000;
  font-size: 4.2vh;
  color: white;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  letter-spacing: 0.03em;
  width: 100%;
  max-width: 614px;
  min-width: 240px;
  margin-bottom: 15px;
  @media (max-width: 700px) {
    font-size: 26px;
  }
`;

const ValidWrap = styled.div`
  max-width: 550px;
  width: 100%;
  min-width: 100px;
  margin: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .message {
    font-family: Poppins;
  }
`

const CheckValidity = styled.form`
  border: 3px solid white;
  display: flex;
  max-width: 550px;
  width: 100%;
  min-width: 100px;

  /* margin: 32px 16px; */
  input {
    outline-width: 0;
    border: none;
    flex: 1;
    display: flex;
    font-size: 1.2em;
    /* width: 100%; */
    font-weight: 500;
    color: white;
    background: inherit;
    padding: 12px;

  }
  .check {
    font-size: 1.2em;
    font-weight: 500;
    background-color: green;
    border: none;
    color: white;
    padding: 12px 16px;
    border-left: 3px solid white;
  }
  
  @media(max-width: 650px) {
    flex-direction: column;
    max-width: 450px;
    .check {
      border-left: 0px;
      border-top: 3px solid white;
    }
  }
  @media(max-width: 450px) {
    max-width: 320px;
  }
`

const Countdown = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 1000;
  font-size: 24px;
  color: white;
  text-align: center;
  font-family: Poppins;
  margin-bottom: 64px;

  
`;

const MintButton = styled.button`
display: flex;
// flex-direction: row;
justify-content: center;
align-items: center;
padding: 2px 36px;
font-family: Poppins;
//width: 250x;
//height: 76px;
background: #7877e6;

font-weight: 600;
color: white;
cursor: pointer;
font-size: 18px;
transition: 0.5s;
:hover {
  padding: 6px 40px;
  font-size: 24px;
}
 
`;